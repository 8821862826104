<template>
  <div ref="cmBox" class="cm">
    <div class="cm_box" v-for="(item, index) in cms" :key="index">
      <div class="cm_title">
        {{ item[0].split('---')[1] }}
        <span v-show="item[0].split('---')[1] === 'mz'">60</span>
        <span v-show="item[0].split('---')[1] === '8A'">98</span>
      </div>
      <div v-for="(citem, cindex) in item" :key="cindex">
        {{ citem }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lookForCM',
  data() {
    return {
      ws: {},
      cms: {}
    }
  },
  mounted() {
    this.connection()
  },
  methods: {
    connection() {
      const that = this
      this.ws = new WebSocket('wss://zyclen.cn/cm')
      this.ws.onopen = function(e) {
        console.log('连接服务器成功！', e)
      }
      this.ws.onclose = function(e) {
        console.log('连接关闭', e)
      }
      this.ws.onerror = function(e) {
        console.log('连接出错', e)
      }
      this.ws.onmessage = function(e) {
        console.log('接收消息', e.data, e.data.split('---')[1])
        if(e.data && e.data !== '服务器连接成功！') {
          const name = e.data.split('---')[1]
          if(that.cms[name]) {
            if (that.cms[name].length < 30) {
              that.cms[name].push(e.data)
            } else {
              that.cms[name] = []
              that.cms[name].push(e.data)
            }
          } else {
            that.$set(that.cms, name, [])
            // that.cms[name] = []
            that.cms[name].splice(1)
            that.cms[name][0] = e.data
            console.log('cunzaima ---------', name, that.cms, that.cms[name])
            // that.cms[name].push(e.data)
          }
          that.$nextTick(() => {
            console.log('cmBox--', that.$refs.cmBox)
            that.$refs.cmBox.scrollTop = that.$refs.cmBox.scrollHeight
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss'
</style>